<template>
  <div class="realQuestion">
    <div class="acitveMathType" @click="mathTypeVisible = true">
      {{ MATH_TYPE[ activeMathType ].name }}
      <van-icon name="arrow-down"/>
    </div>

<!--    <p class="title">真题精讲精练(提效版)</p>-->
<!--    <p class="desc">近十年真题思路精编，附带精准音频讲解，适合强化/真题阶段</p>-->

    <img @click="$router.push('/refactor')" class="assistant" src="@/assets/img/realQuestion/banner.png" alt=""/>

    <div class="card" v-if="!loading">
      <div
          v-for="(item, index) in sections"
          @click="handleOpenSection(index)"
          :key="index"
      >
        <section-card
            :index="index + 1"
            :is-active="activeSection === index"
            :section="item"
            @question-click="({question, questionIdx}) => handleQuestionClick(0, index, questionIdx, question)"
        />
      </div>
    </div>

    <van-overlay :show="mathTypeVisible" @click="mathTypeVisible = false">
      <div class="wapper">
        <div class="modalContent">
          <p class="modalContent__title">请选择考试科目</p>
          <div class="math-list">
            <div class="math-list-inner">
              <div v-for="item in MATH_TYPE" class="math" :key="item.name">
                <div :class="'math-item ' + item.className" @click="handleSelectMath(item)">
                  {{ item.name }}
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="loading">
      <div class="wapper">
        <van-loading type="circular" vertical>
          加载中...
        </van-loading>
      </div>
    </van-overlay>

    <propPay ref="propPay" />

  </div>
</template>

<script type="application/ecmascript">
import propPay from '@/components/pay.vue'
import { Toast } from 'vant'
import SectionCard from '@/components/SectionCard';
import { MATH_TYPE_ENUM, PAPER_TYPE, REAL_QUESTION_BUY_URL } from '@/consts';
import directoryMixin from '@/mixins/directoryMixin';
import shareMixin from '@/mixins/shareMixin';

const MATH_TYPE = {
  [ MATH_TYPE_ENUM.MATH_ONE ]: {name: '数学一', value: MATH_TYPE_ENUM.MATH_ONE, className: 'math-one'},
  [ MATH_TYPE_ENUM.MATH_TWO ]: {name: '数学二', value: MATH_TYPE_ENUM.MATH_TWO, className: 'math-two'},
  [ MATH_TYPE_ENUM.MATH_THREE ]: {name: '数学三', value: MATH_TYPE_ENUM.MATH_THREE, className: 'math-three'},
  // [ MATH_TYPE_ENUM.MATH_FOUR ]: {name: '数学一补充', value: MATH_TYPE_ENUM.MATH_FOUR, className: 'math-four'},
  // [ MATH_TYPE_ENUM.MATH_FIVE ]: {name: '数学二补充', value: MATH_TYPE_ENUM.MATH_FIVE, className: 'math-five'},
  // [ MATH_TYPE_ENUM.MATH_SIX ]: {name: '数学三补充', value: MATH_TYPE_ENUM.MATH_SIX, className: 'math-six'}
}

export default {
  components: {propPay,SectionCard},
  mixins: [directoryMixin, shareMixin],
  data () {
    return {
      MATH_TYPE,
      REAL_QUESTION_BUY_URL,
      loading: false,
      activeMathType: MATH_TYPE_ENUM.MATH_ONE,
      mathTypeVisible: false,
      activeSection: null,
    }
  },
  computed: {
    sections () {
      if (this.directory.chapters && this.directory.chapters[ 0 ]) {
        return this.directory.chapters[ 0 ].sections
      }
      return []
    }
  },
  methods: {
    // 选择数学类型
    handleSelectMath (item) {
      this.queryAuthDirectory(item.value)
    },

    // 切换展开目录
    handleOpenSection (index) {
      this.activeSection = index
    },

    handleQuestionClick (chapterIndex, sectionIndex, questionIdx, question) {
      this.$router.push({
        path: '/question',
        query: {
          chapterIndex,
          sectionIndex,
          questionIdx,
          questionID: question.questionID,
          type: PAPER_TYPE.REAL_QUESTION,
          mathType: this.activeMathType
        }
      })
    },

    // 获取当前做题进度
    async getCurInfo () {
      this.loading = true
      try {
        const ret = await this.$api.queryQuestionResult({
          param: {
            catalog_id: '1',
          }
        });
        if (!ret.math_type) {
          this.mathTypeVisible = true
        } else {
          await this.realQuestionDirectory({
            math_type: ret.math_type
          });
          this.activeMathType = ret.math_type || MATH_TYPE_ENUM.MATH_ONE
          this.activeSection = parseInt(ret.sectionIdx) || 0
          this.$store.state.realQuestion.curQuestionId = ret.questionID
          this.$store.state.realQuestion.curQuestionIdx = parseInt(ret.questionIdx) || 0
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false
      }
    },

    // 权限校验
    async queryAuthDirectory (value) {
      this.loading = true
      try {
        const ret = await this.$api.queryRealQuestionDirectory({
          param: {
            catalog_id: 1,
            math_type: value
          },
          method: 'get'
        });
        // 是app用户
        if (ret.maths[ 0 ].is_registed) {
          // 是否解锁
          if (ret.maths[ 0 ].is_unlock) {
            this.activeMathType = value
            this.$store.commit('realQuestion/updateDirectory', {
              directory: {...ret.maths[ 0 ].books[ 0 ]} || {chapters: []}
            });
            this.loading = false
            this.mathTypeVisible = false
            return;
          }
          wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active=4'});
          return false
          let param = {
            openid: localStorage.openid,
            username: 'haha'
          };
          const registerRet = await this.$api.register({param, method: 'post'});
          if (registerRet.wq_access_token) {
            localStorage.access_token = registerRet.wq_access_token;

            // 再次调用目录判断is_unlock
            const result = await this.$api.queryRealQuestionDirectory({
              param: {
                catalog_id: 1,
                math_type: value
              },
              method: 'get'
            });
            if (!result.maths[ 0 ].is_unlock) {
              wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active=4'});
              // this.$refs.propPay.showBottom = true;
              return;
            }
            this.activeMathType = value
            this.$store.commit('realQuestion/updateDirectory', {
              directory: {...ret.maths[ 0 ].books[ 0 ]} || {chapters: []}
            });
          }
        } else {
          // this.$router.push('/token');
          wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active=4'});
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.initShare()
    await this.getCurInfo()
  }
}
</script>

<style lang="less" scoped>
.realQuestion {
  padding: 16px 0px;

  .acitveMathType {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #415fd5;
    line-height: 17px;
  }

  .title {
    height: 24px;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2a3155;
    line-height: 24px;
    margin: 4px 0;
  }

  .desc {
    height: 18px;
    font-size: 12px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #9498aa;
    line-height: 18px;

  }

  .assistant {
    margin: 16px 0;
  }

  .card {
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 16px;
    margin: 0 20px;
  }
}

.wapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modalContent {
  background: white;
  padding: 32px 24px 12px;

  &__title {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2a3155;
    line-height: 25px;
    margin-bottom: 24px;
  }

  .math-list {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .math-list-inner {
    // height: 250px;
  }

  .math {
    &-item {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
      padding: 18px 87px;
      border-radius: 8px;
      margin-bottom: 12px;

      &.math-one {
        background: linear-gradient(135deg, #415fd5 0%, #9070f4 100%);
      }

      &.math-two {
        background: linear-gradient(135deg, #4FBC8A 0%, #2F8879 100%);
      }

      &.math-three {
        background: linear-gradient(135deg, #F9C200 0%, #FF9051 100%);
      }

      &.math-four {
        background: linear-gradient(135deg, #ffbb96 0%, #ad2102 100%);
      }

      &.math-five {
        background: linear-gradient(135deg, #ffe58f 0%, #ad6800 100%);
      }

      &.math-six {
        background: linear-gradient(135deg, #fffb8f 0%, #ad8b00 100%);
      }
    }
  }
}
</style>
